export class Utils {
    public static getUrlParam(param: string) {
        let searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(param);
    }

    public static cleanId(id: string): string | null {
        const cleaned = id.trim().replace(/\./g, '');
        if (cleaned.trim() != '') {
            return cleaned;
        } else {
            return null;
        }
    }

    public static formatIdForSpeech(id: string) {
        let result = id;

        if (result.length === 6) {
            result = result.substr(0, 2) + ', ' + result.substr(2, 2) + ', ' + result.substr(4, 2);
        }

        return result;
    }

    public static formatWithDots(id: string) {
        return id.substr(0, 2) + '.' + id.substr(2, 2) + '.' + id.substr(4, 2);
    }
}
