import {LoadHelper} from './model/LoadHelper';
import {CreateHelper} from './model/CreateHelper';
import {CollapseHelper} from './model/CollapseHelper';

$(() => {
    // const API_HOST = 'https://api.zapit.in/items';
    const API_HOST = process.env.API_URL;

    if (API_HOST == undefined) {
        throw new Error('API_URL environment variable not specified');
    }

    CollapseHelper.init({
        btnCollapseSelector: '.btnCollapse',
        btnExpandSelector: '.btnExpand',
        sectionAreaSelector: '.sectionArea',
    });

    CreateHelper.init({apiHost: API_HOST});
    LoadHelper.init({apiHost: API_HOST});
});
