import {ReadIdHelper} from './ReadIdHelper';
import {Utils} from './Utils';

interface Params {
    apiHost: string;
}

export class CreateHelper {
    private static params: Params;
    private static $saveResult: JQuery;
    private static $btnCreate: JQuery;
    private static $txtAreaNewClip: JQuery;
    private static $inpSavedId: JQuery;

    public static init(params: Params) {
        this.params = params;
        this.$saveResult = $('#saveResult');
        this.$btnCreate = $('#btnCreate');
        this.$txtAreaNewClip = $('#txtAreaNewClip');
        this.$inpSavedId = $('#inpSavedId');

        ReadIdHelper.init({apiHost: params.apiHost, $inpSavedId: this.$inpSavedId});

        this.setupDomEvents();
    }

    private static setupDomEvents() {

        this.$btnCreate.on('click', () => {
            const contents = String(this.$txtAreaNewClip.val());

            if (contents.trim() != '') {
                CreateHelper.createClip(contents);
            } else {
                alert('Type something to save');
            }
        });
    }

    public static async createClip(contents: string) {
        this.$saveResult.addClass('visible').find('label').text('creating...');

        try {
            ReadIdHelper.setState('HIDDEN');
            this.$btnCreate.attr('disabled', 1);
            this.$saveResult.find('input').hide();

            const result = await $.ajax({
                type: 'POST',
                crossDomain: true,
                contentType: 'application/json',
                dataType: 'text',
                url: this.params.apiHost,
                data: JSON.stringify({
                    contents: contents,
                }),
            });
            this.$saveResult.find('label').text('Code');

            this.$inpSavedId.val(Utils.formatWithDots(result)).show();
            ReadIdHelper.setState('NOT_PLAYING');
        } catch (ex) {
            ReadIdHelper.setState('NOT_PLAYING');
            this.$saveResult.hide().removeClass('visible');
            if (ex.responseText) {
                alert(ex.responseText);
            } else {
                alert('An unknown error occurred.');
            }

            console.error(ex);
        } finally {
            this.$btnCreate.text('Create').removeAttr('disabled');
        }
    }
}
