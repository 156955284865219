export interface Params {
    btnCollapseSelector: string;
    btnExpandSelector: string;
    sectionAreaSelector: string;
}

export class CollapseHelper {
    public static init(params: Params) {
        $(params.btnCollapseSelector).on('click', (event) => {
            this.expandCollapseSection($(event.target).parents(params.sectionAreaSelector), 'collapse', true);

        })

        $(params.btnExpandSelector).on('click', (event) => {
            this.expandCollapseSection($(event.target).parents(params.sectionAreaSelector), 'expand', true);
        })
    }

    public static collapseCreateClipSection(withAnimation: boolean) {
        this.expandCollapseSection($('.createSection'), 'collapse', withAnimation);
    }

    private static expandCollapseSection($sectionArea: JQuery, mode: 'expand' | 'collapse', withAnimation: boolean) {
        let hideWhenCollapsed = $sectionArea.find('.hideWhenCollapsed');
        let showWhenCollapsed = $sectionArea.find('.showWhenCollapsed');
        let slideUpWhenCollapsed = $sectionArea.find('.slideUpWhenCollapsed');

        if (mode === 'collapse') {
            hideWhenCollapsed.hide();
            showWhenCollapsed.show();
            if (withAnimation) {
                slideUpWhenCollapsed.slideUp();
            } else {
                slideUpWhenCollapsed.hide();
            }
        } else if (mode === 'expand') {
            hideWhenCollapsed.show();
            showWhenCollapsed.hide();
            if (withAnimation) {
                slideUpWhenCollapsed.slideDown();
            } else {
                slideUpWhenCollapsed.show();
            }
        }
    }
}
