import {CollapseHelper} from './CollapseHelper';
import {Utils} from './Utils';

interface Params {
    apiHost: string;
}

export class LoadHelper {
    private static params: Params;

    private static $btnLoad: JQuery;
    private static $loadResult: JQuery;
    private static $zapit: JQuery;

    public static init(params: Params) {
        this.params = params;

        this.$btnLoad = $('#btnLoad');
        this.$loadResult = $('.loadResult')
        this.$zapit = $('#zapit');

        this.setupDomEvents();

        this.loadClipFromUrl();
    }

    private static loadClipFromUrl() {
        let idFromQuery = Utils.getUrlParam('z');

        if (idFromQuery != null) {
            idFromQuery = Utils.cleanId(idFromQuery);
            if (idFromQuery != null) {
                this.$zapit.val(idFromQuery);
                this.loadClip(idFromQuery);
            }
        }
    }

    private static setupDomEvents() {
        this.$btnLoad.on('click', () => {
            let id = Utils.cleanId(String(this.$zapit.val()));

            if (id !== null) {
                this.loadClip(id);
            } else {
                alert('Enter a Shareable ID to load');
            }
        });

        this.$zapit.on('keypress',  (e) => {
            if (e.which == 13) {
                this.$btnLoad.trigger('click');
            }
        });
    }

    public static async loadClip(id: string) {
        CollapseHelper.collapseCreateClipSection(false);

        try {
            this.$btnLoad.text('Loading ...').attr('disabled', 1);


            const result = await $.ajax({
                type: 'GET',
                crossDomain: true,
                contentType: 'application/json',
                dataType: 'text',
                url: this.params.apiHost + '/' + encodeURIComponent(id),
            });

            this.displayClip(result);

        } catch (ex) {
            if (ex.responseText) {
                alert(ex.responseText);
            } else {
                alert('An unknown error occurred.');
            }
            // alert(JSON.stringify(ex));
            console.error(ex);
        } finally {
            this.$btnLoad.text('Load').removeAttr('disabled');
        }
    }

    private static displayClip(data: string) {
        this.$loadResult.slideDown();

        $('#loadedData').text(data).trigger('focus').trigger('select');

        // if data is a URL, redirect to it
        let dataUrl = data;

        if (LoadHelper.isValidHttpUrl(dataUrl)) {
            window.location.href = dataUrl;
        }
       /* const re = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');

        if (re.test(dataUrl)) {
            if (!dataUrl.toLowerCase().startsWith('http://') && !dataUrl.toLowerCase().startsWith('https://')) {
                dataUrl = 'https://' + dataUrl;
            }
            window.location.href = dataUrl;
        }*/
    }

    private static isValidHttpUrl(url: string) {
        let urlObject;

        try {
            urlObject = new URL(url);
        } catch (ex) {
            return false;
        }

        return urlObject.protocol === "http:" || urlObject.protocol === "https:";
    }
}
