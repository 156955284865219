import {Utils} from './Utils';
// @ts-ignore
import imgAutoRenew from  '../assets/ic_autorenew.png';
// @ts-ignore
import imgVolumeUp from '../assets/volume-up-cropped.png';
// @ts-ignore
import imgPause from '../assets/pause-cropped.png';

interface Params {
    apiHost: string;
    $inpSavedId: JQuery;
}

export class ReadIdHelper {
    private static readonly MAX_AUDI_REPEAT_NO = 4;
    private static readonly AUDIO_REPEAT_DELAY_MS = 2.5* 1000;
    private static audioPlaying: boolean = false;
    private static audioRepeatNo = 0;
    private static $audioPlayback: JQuery;
    private static $audioSource: JQuery;
    private static $btnReadId: JQuery;
    private static params: Params;

    public static init(params: Params) {
        this.params = params;
        this.$audioPlayback = $('#audioPlayback');
        this.$audioSource = $('#audioSource')
        this.$btnReadId = $('#btnReadId')

        this.setupDomEvents();
    }

    private static setupDomEvents() {
        this.$btnReadId.on('click', () => {
            const id = Utils.cleanId(String(this.params.$inpSavedId.val()));
            if (id != null) {
                ReadIdHelper.readId(id);
            }
        });

    }

    public static async readId(id: string) {
        if (this.stopIfPlaying()) {
            return ;
        }

        if (id != null) {
            id = Utils.formatIdForSpeech(id);

            this.setState('LOADING');

            try {
                const result = await $.ajax({
                    type: 'POST',
                    crossDomain: true,
                    contentType: 'application/json',
                    dataType: 'text',
                    url: this.params.apiHost + '/read',
                    data: JSON.stringify({
                        id: id,
                    }),
                });

                this.setState('PLAYING', result);

            } catch (ex) {
                if (ex.responseText) {
                    alert(ex.responseText);
                } else {
                    alert('An unknown error occurred.');
                }

                console.error(ex);
                // btnReadId.text('Read');
                this.setState('NOT_PLAYING')
            }
        }
    }

    public static setState(state: 'HIDDEN' | 'NOT_PLAYING' | 'LOADING'): void;
    public static setState(state: 'PLAYING', src: string): void;
    public static setState(state: 'HIDDEN' | 'NOT_PLAYING' | 'PLAYING' | 'LOADING', src?: string): void {
        let imgSrc: string;
        let title: string;

        if (state === 'LOADING') {
            imgSrc = imgAutoRenew;
            title = 'Loading';
        } else if (state === 'NOT_PLAYING') {
            imgSrc = imgVolumeUp;
            title = 'Play';
            this.stopIfPlaying();
        } else if (state === 'PLAYING') {
            if (typeof src === 'undefined') {
                throw new Error('Missing src');
            }
            imgSrc = imgPause;
            title = 'Pause';

            this.startPlaying(src);
        } else if (state === 'HIDDEN') {
            this.$btnReadId.hide();
            this.stopIfPlaying();
            return;
        } else {
            throw new Error('Invalid state');
        }

        this.$btnReadId.attr('src', imgSrc).attr('alt', title).attr('title', title).show();
    }

    private static startPlaying(src: string) {
        this.$audioSource.attr('src', src);
        this.audioPlaying = true;
        const audioPb = this.$audioPlayback.get(0) as HTMLAudioElement;
        $(audioPb).trigger('load');

        $(audioPb).on('ended', () => {
            setTimeout(() => {
                this.onAudioEnded();
            }, this.AUDIO_REPEAT_DELAY_MS);
        });
    }

    private static onAudioEnded() {
        if (this.audioPlaying) {
            this.audioRepeatNo++;
            if (this.audioRepeatNo < this.MAX_AUDI_REPEAT_NO) {
                const audioPb = this.$audioPlayback.get(0) as HTMLAudioElement;
                $(audioPb).trigger('play');
            } else {
                this.setState('NOT_PLAYING');
            }
        }
    }

    /**
     * Returns true if was playing, false otherwise
     */
    private static stopIfPlaying(): boolean {
        const audioPb = this.$audioPlayback.get(0) as HTMLAudioElement;
        this.audioRepeatNo = 0;
        this.audioPlaying = false;

        if (!audioPb.paused) {
            // Audio is playing, pause it
            $(audioPb).trigger('pause');
            // btnReadId.text('Read');
            this.setState('NOT_PLAYING')
            return true;
        } else {
            return false;
        }
    }
}
